import { Box, Button, Card, CardContent, Grid, IconButton, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Navbar from '../../components/Navbar';
import { useGetFilterJobsQuery } from '../../redux/services/jobs/JobListService';
import Footer from '../../components/Footer';
import { useEffect } from 'react';

function JobsPage() {
    // const [jobs, setJobs] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const { searchTerm, location: searchLocation, experience } = location.state || {};

    const { data: jobs = [], refetch , error, isLoading } = useGetFilterJobsQuery({
        title: searchTerm,
        location: searchLocation,
        experience
    });

    useEffect(() => {
        refetch();
    },[])

    return (
        <>
            <Navbar />
            <Box sx={{ backgroundColor: "#ffffff", minHeight: "50vh", mt: 11, p: 0 }}>
                <Box
                    sx={{
                        display: 'flex', // Add flexbox
                        flexDirection: 'column', // Align items vertically
                        justifyContent: 'center', // Center items vertically
                        alignItems: 'center', // Center items horizontally
                        backgroundImage: `url(${"https://static.zohocdn.com/recruit/images/cover1.0e02dce62a260cd1dbbafeacf59e558a.jpg"})`,
                        backgroundSize: '110%', // Zoom effect
                        backgroundPosition: 'center',
                        color: '#fff',
                        textAlign: 'center',
                        py: 5,
                        position: 'relative',
                        '&::before': {
                            content: '""',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: 'rgba(0, 0, 0, 0.6)', // Optional overlay for better text contrast
                            zIndex: 1,
                        },
                        '& > *': {
                            position: 'relative',
                            zIndex: 2,
                            maxWidth: '700px',
                            mx: 'auto',
                        }
                    }}
                >
                    <IconButton 
                        onClick={() => navigate(-1)} 
                        sx={{ 
                            position: 'absolute', 
                            top: 16, // Adjusted for better visibility
                            left: 16, // Adjusted for better visibility
                            zIndex: 3, // Ensure it appears above other elements
                            color: '#fff' // Ensure the icon is visible against the background
                        }}
                    >
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h6" sx={{ mb: 2 }}>
                        Find the career of your dreams
                    </Typography>
                    <Typography variant="h2" sx={{ fontWeight: 'bold', mb: 2 }}>
                        We're more than just a workplace. We're a family.
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 4 }}>
                        We know that finding a meaningful and rewarding job can be a long journey. Our goal is to make that process as easy as possible for you, and to create a work environment that's satisfying - one where you'll look forward to coming to every day. Start your journey with us by browsing available jobs.
                    </Typography>
                    <Button variant="contained" color="primary">
                        View Openings
                    </Button>
                </Box>
            </Box>

            <Box sx={{ p: 4, textAlign: 'center' }}>
                <Typography variant="h4" sx={{ mb: 2 }}>
                    Apply Now
                </Typography>
                <Typography variant="h6" sx={{ mb: 4 }}>
                    Current Openings
                </Typography>
                <Grid container spacing={2} justifyContent="center" sx={{ px: 2 }}>
                    {jobs.length > 0 ? (
                        jobs.map((job, index) => (
                            <Grid item xs={12} sm={8} md={4} key={job.id} sx={{ maxWidth: '700px' }}>
                                <Card
                                    variant="outlined"
                                    sx={{
                                        transition: 'transform 0.3s, box-shadow 0.3s',
                                        '&:hover': {
                                            transform: 'scale(1.05)',
                                            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)',
                                        },
                                    }}
                                    onClick={() => navigate(`/jobs/Careers/${job?.id}/${encodeURIComponent(job?.title)}`, { state: { logo: job.company_logo, company: job.company_name , companyId: job.company_id } })}
                                >
                                    <CardContent sx={{ textAlign: 'left' }}>
                                        <Typography variant="h6" sx={{ fontWeight: 'bold' }} color="primary">
                                            {job.title}
                                        </Typography>
                                        <Typography variant="subtitle2" color="textSecondary">
                                            {job.company_name}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            {job.Experience} - {job.Experience_max} Yrs | {job.salary_min} - {job.salary_max} PA | {job.location}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                                            {/* {job.description} */}
                                        </Typography>
                                        {/* <Typography variant="caption" color="textSecondary" sx={{ mt: 2, display: 'block' }}>
                                            {job.tags.join(' • ')}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
                                            Posted by {job.posted_by}
                                        </Typography> */}
                                        <Typography variant="caption" color="textSecondary" sx={{ mt: 1 }}>
                                            {new Date(job.posted_date).toISOString().split('T')[0]}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))
                    ) : (
                        <Grid item xs={12} sx={{ textAlign: 'center', mt: 4 }}>
                            <Card variant="outlined" sx={{ maxWidth: '300px', mx: 'auto', p: 2 }}>
                                <CardContent>
                                    <Typography variant="h6" color="textSecondary">
                                        No results found
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Try adjusting your search criteria.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    )}
                </Grid>
                <Button variant="outlined" sx={{ mt: 4, borderRadius: '20px' }}>
                    10 MORE
                </Button>
            </Box>
            <Footer />
        </>
    )
}

export default JobsPage